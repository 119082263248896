.contact-div-main {
    display: flex;
}

.contact-div-main > * {
    flex: 1;
}

.contact-title {
    font-size: 50px;
    font-weight: 400;
    margin-left: 50px;
}

.contact-detail,
.contact-detail-email {
    margin-top: 20px;
    font-size: 30px;
    font-weight: 700;
    font-style: italic;
    color: #868e96;
    text-decoration: none;
}

.contact-detail:hover,
.contact-detail-email:hover {
    color: black;
    text-shadow: 2px 1px 2px #b5b5b5;
    transition: all 0.3s;
}

.contact-subtitle {
    font-size: 25px;
    font-weight: 600;
    font-style: italic;
    margin-left: 50px;
}

.contact-text-div {
    margin-top: 1.5rem;
    margin-left: 50px;
}

.contact-margin-top {
    margin-top: 4rem;
}

.contact-image-div > img {
    max-width: 100%;
    height: auto;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
    .contact-title {
        font-size: 50px;
        font-weight: 400;
        text-align: center;
    }

    .contact-title {
        font-size: 25px;
        text-align: center;
    }

    .contact-subtitle {
        font-size: 16px;
        line-height: normal;
        text-align: center;
    }

    .contact-text-div {
        text-align: center;
        margin-top: 1.5rem;
    }

    .contact-detail,
    .contact-detail-email {
        font-size: 20px;
    }

    .contact-image-div {
        display: none;
    }
}
