.container {
  display: flex;
  justify-content: center;
}

.square:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
}

.square {
  width: 440px;
  height: 430px;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 20px 50px #d9dbdf;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 30px;
}

.mask {
  clip: rect(0px, 460px, 220px, 0px);
  border-radius: 4px;
  position: absolute;
}

.blog-card-image {
  width: 440px;
}

.blog-card-title {
  margin: auto;
  text-align: left;
  margin-top: 240px;
  padding: 0 30px;
  font-size: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blog-card-subtitle {
  text-align: justify;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 12px;
  color: #c8c8c8;
  line-height: 18px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.button {
  background-color: #a066fb;
  color: white;
  width: 90px;
  padding: 10px 18px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 70px;
  font-size: 14px;
  cursor: pointer;
}

/* Media Query */
@media (max-width: 1380px) {
  .card-title {
    font-size: 25px;
  }
}
@media (max-width: 768px) {
  .mask {
    clip-path: none;
    position: relative
  }
  .blog-card-title {
    margin-top: 10px;
  }
  .blog-card-image {
    width: 100%;
  }

  .square {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
  }
}
